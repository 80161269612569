import React, { useState } from 'react';
import cx from 'classnames';
import { useRef } from 'react';
import useClickOutsideDetector from 'lib/hooks/useClickOutsideDetector';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCitationList,
  duplicateCurrentList,
} from 'redux/modules/citation-list-module';
import IconMore from 'static/svgs/icon/20/more-vertical.svg';
import { ListMetadata } from 'types/citation-list';
import { Store } from 'redux/store';
import { isAllowedToEdit } from 'redux/selectors/citation-list-selector';
import css from './citation-list-options.module.css';

interface CitationListProps {
  citationList: ListMetadata;
  openRename: () => void;
}

const CitationListOptions: React.FC<CitationListProps> = ({
  citationList,
  openRename,
}) => {
  const dispatch = useDispatch();
  const citationListLength = useSelector(
    (state: Store) => state.citationList.lists.length,
  );
  const email = useSelector((state: Store) => state.auth.data?.email);
  const allowedToEdit = isAllowedToEdit(citationList, email);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutsideDetector(wrapperRef, () => setShowOptions(false));

  const handleShowOptions = (): void => {
    setShowOptions(!showOptions);
  };

  const handleRename = (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ): void => {
    e.stopPropagation();
    setShowOptions(false);
    openRename();
  };
  const handleDuplicate = (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ): void => {
    e.stopPropagation();
    setShowOptions(false);
    dispatch(duplicateCurrentList());
  };

  const handleRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    setShowOptions(false);
    dispatch(deleteCitationList(citationList.id));
  };
  return (
    <div
      ref={wrapperRef}
      className={cx(css.wrapper, showOptions && css.selected)}
      tabIndex={0}
      role="button"
      onClick={handleShowOptions}
      onKeyPress={handleShowOptions}
    >
      <div>
        <button className={css.optionsIcon}>
          <IconMore />
        </button>
      </div>
      <span
        className={cx(
          css.dropdownListContainer,
          showOptions && css.dropdownListContainerActive,
        )}
      >
        <ul className={css.dropdownList}>
          <li className={css.dropdownListItem}>
            {allowedToEdit && <button onClick={handleRename}>Rename</button>}
          </li>
          <hr className={css.dropdownListItemLine} />
          <li className={css.dropdownListItem}>
            <button onClick={handleDuplicate}>Duplicate</button>
          </li>
          {/* deleting our last citation list breaks the app */}
          {citationListLength > 1 && allowedToEdit && (
            <>
              <hr className={css.dropdownListItemLine} />
              <li
                className={cx(css.dropdownListItem, css.dropdownListItemDelete)}
              >
                <button onClick={handleRemove}>Delete</button>
              </li>
            </>
          )}
        </ul>
      </span>
    </div>
  );
};

export default CitationListOptions;
