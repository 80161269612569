import { breakpoints, colors, defaults, text } from 'css/variables';
import React, { useRef, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useSelector } from 'react-redux';
import { getCurrentList } from 'redux/selectors/citation-list-selector';
import * as clipboard from 'clipboard-polyfill';
import { mod } from 'lib/mod';
import { Store, useAppDispatch } from 'redux/store';
import {
  disableSharingForCurrentList,
  enableSharingForCurrentList,
  toggleAllowEditing,
} from 'redux/modules/citation-list-module';
import createWebUrl from 'lib/create-web-url';
import Button from 'components/common/button';
import Toggle from 'components/common/toggle';
import { useRouter, withRouter } from 'next/router';
import css from './sharing-modal.module.css';
import cx from 'classnames';

const CloseIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx="9.5"
        cy="9.5"
        r="9.5"
        fill="#7728F9"
        fillRule="nonzero"
        strokeWidth="2"
      />
      <path
        stroke={`${colors.white}`}
        d="M5.5 0L5.5 11M0 5.5L11 5.5"
        transform="rotate(45 2.672 12.328)"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const TickMarkSVG: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 12l5 5l10 -10" />
  </svg>
);

const UserAddSVG = (): JSX.Element => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 12.3333C0.5 10.7685 1.76853 9.5 3.33333 9.5H10.6667C12.2315 9.5 13.5 10.7685 13.5 12.3333C13.5 12.9777 12.9777 13.5 12.3333 13.5H1.66667C1.02233 13.5 0.5 12.9777 0.5 12.3333Z"
      fill="#B080FF"
      stroke="#B080FF"
    />
    <circle cx="7" cy="4" r="3.5" fill="#B080FF" stroke="#B080FF" />
    <path d="M14 5V7M14 9V7M14 7H16M14 7H12" stroke="#B080FF" />
  </svg>
);

const SharableLink = ({ shareId }: { shareId: string }) => {
  const link = createWebUrl(`/s/${shareId}`);
  const [showCopied, setShowCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCopyClick = () => {
    inputRef.current?.focus();
    inputRef.current?.select();
    clipboard.writeText(link).then(() => {
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    });
  };

  return (
    <div>
      <div className={css.input}>
        <input
          className={css.inputClass}
          value={link}
          readOnly
          onClick={handleCopyClick}
          ref={inputRef}
        />
        <div className={css.copiedButtonWrapper}>
          {showCopied && (
            <button className={css.copyButton}>
              <TickMarkSVG />
              <span className={css.copiedTextWrapper}>Copied</span>
            </button>
          )}
          {!showCopied && (
            <button
              className={cx(css.copyButton, css.selectable)}
              onClick={handleCopyClick}
            >
              Copy
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const SharingModal: React.FC = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [allowEdit, setAllowEdit] = useState<'activated' | 'deactivated'>(
    'deactivated',
  );
  const list = useSelector(getCurrentList);
  const isLoggedIn =
    useSelector((state: Store) => state.auth.status) === 'present';
  const dispatch = useAppDispatch();
  const email = useSelector((state: Store) => state.auth.data?.email);
  const isOwner =
    typeof email === 'string' && !list.collaborators.includes(email);
  const router = useRouter();

  const handleShareListClick = () => {
    if (!isLoggedIn) {
      router.push(
        `/signup?next=${encodeURIComponent(router.asPath)}&required=true`,
      );
    } else {
      setModalOpen(true);
    }
  };
  return (
    <div className={css.modalContainer}>
      <button
        className={cx(css.shareListButton, css.wrapper, css.button)}
        onClick={handleShareListClick}
      >
        <UserAddSVG />
        <span className={css.shareListText}>Share list</span>
      </button>
      <Modal
        classNames={{
          overlay: 'sharingModalOverlay',
          modal: 'sharingModal',
        }}
        center
        closeIcon={<CloseIcon />}
        open={modalOpen}
        onClose={() => {
          setDeleteConfirmOpen(false);
          setModalOpen(false);
        }}
      >
        {!deleteConfirmOpen && (
          <div
            className={list.shareId ? css.shareTitleLeft : css.shareTitleCenter}
          >
            Share “{list.name}”
          </div>
        )}
        {deleteConfirmOpen && (
          <div className={css.shareTitleCenter}>
            Are you sure you want to disable sharing?
          </div>
        )}
        <div className={css.shareModalContent}>
          {list.shareId && !deleteConfirmOpen ? (
            <>
              <div className={css.shareLinkMessage}>
                {isOwner
                  ? 'Share the link below with others to collaborate with them on this list.'
                  : 'Use the link below to share this list with others.'}
              </div>
              <div>
                <span className={css.boldText}>Sharable link</span>
                <SharableLink shareId={list.shareId} />
              </div>
              {isOwner && (
                <div className={css.sharingOptions}>
                  <span className={css.allowEditingContent}>
                    <Toggle
                      onChange={(activation) => {
                        setAllowEdit(activation);
                        dispatch(toggleAllowEditing());
                      }}
                      toggle={allowEdit}
                    />
                    <span className={css.allowEditingText}>Allow editing</span>
                  </span>
                  <button
                    onClick={() => setDeleteConfirmOpen(true)}
                    className={cx(css.disableSharingButton, css.button)}
                  >
                    Disable sharing
                  </button>
                </div>
              )}
              {isOwner && (
                <div className={css.allowEditingDescriptionText}>
                  Allow others to add and remove citations to the list
                </div>
              )}
              <div className={css.collaboratorsContent}>
                <div className={cx(css.boldText, css.collaboratorsText)}>
                  Collaborators
                </div>
                <ul className={css.collaboratorList}>
                  <li className={css.li} key={list.owner}>
                    {(list.owner || '') + (isOwner ? ' (you)' : ' (owner)')}
                  </li>
                  {list.collaborators.map((collabEmail) => (
                    <li className={css.li} key={collabEmail}>
                      {collabEmail + (collabEmail === email ? ' (you)' : '')}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : deleteConfirmOpen ? (
            <div className={css.shareListModalDeleteConfirmation}>
              <div>Other collaborators will lose access to this list</div>
              <div className={css.disableSharingWrapper}>
                <Button
                  type="lighter"
                  onClick={() => setDeleteConfirmOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDeleteConfirmOpen(false);
                    dispatch(disableSharingForCurrentList());
                  }}
                  type="warning"
                >
                  Disable Sharing
                </Button>
              </div>
            </div>
          ) : (
            <div className={css.shareListModalWhenPrivate}>
              <div>
                Enable sharing to the citations list
                <br /> with others
              </div>
              <Button
                onClick={() => {
                  if (allowEdit == 'activated') dispatch(toggleAllowEditing());
                  setAllowEdit('deactivated');
                  dispatch(enableSharingForCurrentList());
                }}
              >
                Enable sharing
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(SharingModal);
